import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { PictureData } from "../../../types/PictureData";
import { IDynamicBlockComponent } from "../../dynamicPageBuilder/IDynamicBlockComponent";

type CounterItem = {
  number: number;
  sign: string;
  label: string;
  number_color: string;
  label_color: string;
};

type CountersProps = {
  data: {
    header: string;
    element: Record<string, CounterItem>;
    background: string;
    picture: PictureData;
    textSizeMultiplier: number;
    headerTextColor: string;
  };
};

const Counters: React.FunctionComponent<IDynamicBlockComponent> = ({
  blockData,
}): React.ReactElement => {
  const { data } = blockData as CountersProps;
  const {
    header,
    element = {},
    background: backgroundColor,
    picture,
    textSizeMultiplier,
    headerTextColor,
  } = data;
  const items: Array<CounterItem> = Object.values(element);
  const ref = React.useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const counterDurationInSecond = 3;

  const backgroundImage =
    picture && `url('${process.env.NEXT_PUBLIC_IMG_URL}${picture.publicUrl}')`;

  const isInViewport = (element: HTMLHeadingElement): boolean => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight)
    );
  };

  const handleInViewport = (): void => {
    if (ref.current) {
      const inViewport = isInViewport(ref.current);
      if (inViewport) {
        setIsVisible(inViewport);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleInViewport);

    return () => {
      window.removeEventListener("scroll", handleInViewport);
    };
  });

  useEffect(() => {
    if (isVisible) {
      window.removeEventListener("scroll", handleInViewport);
    }
  });

  useEffect(handleInViewport, []);

  return (
    <div className="countersBlock" style={{ backgroundColor, backgroundImage }}>
      <span style={{ fontSize: 26 * textSizeMultiplier }}>
        <h2
          className="countersTitle"
          style={{ color: headerTextColor }}
          ref={ref}
        >
          {header}
        </h2>
      </span>
      <div className="countersContainer desktop-container">
        {isVisible && (
          <div className="countersContainer__items">
            {items.map((item, index) => (
              <div
                style={{
                  color: item.number_color,
                  fontSize: 26 * textSizeMultiplier,
                }}
                className="countersContainer__items--counter"
                key={"item_" + index}
              >
                <div className="counterNumber">
                  <CountUp
                    duration={counterDurationInSecond}
                    end={item.number}
                    separator=" "
                    suffix={item.sign ?? ""}
                  />
                </div>
                <div
                  style={{ color: item.label_color }}
                  className="counterLabel"
                >
                  {item.label}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Counters;
